import React, { Component } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import Arrow from '../assets/images/right-arrow.svg'

class NewsletterForm extends Component {

  state = {
    email: '',
  }

  _handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  // 2. via `async/await`
  _handleSubmit = async (e) => {
    e.preventDefault();
    let data = {}
    const result = await addToMailchimp(this.state.email, data)
    // I recommend setting `result` to React state
    // but you can do whatever you want
    this.setState({
      result: result.result,
      msg: result.msg
    })
  }

  render() {
    let props = {
      ref: 'form',
      name: 'newsletter',
      className: 'form',
      onSubmit: this._handleSubmit,
    }

    if (this.state.result === 'success')
      return (<p className='newsletter__success'>Thanks for subscribing!</p>)

    return (
      <form {...props}>
        <div className='form__row form__row--subscribe'>
          <input type='email' name='email' onChange={this._handleChange} placeholder='Join our Newsletter' required  />
          <button type='submit'><img src={Arrow} alt='Bio101 - Join Newsletter' /></button>
        </div>
      </form>
    )
  }
}

export default NewsletterForm